<template>
  <div class="work-page nike-all-whites">
  	<!-- Header -->
  	<section class="header">

      <img v-lazy="'/static/images/AW_1.jpg'"/>
    
  		<div class="meta">
  			<p class="client">NIKE</p>
  			<a class="project">ALL WHITES KIT LAUNCH</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>Of the kit. Of the country.</h1>
  			</div>
  			<div class="col-2">
  				<p>For the launch of the New Zealand national team kit, we took inspiration from the kit, the symbols of the country and Maori design.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <div class="two-col">
      <div class="col" style="width: 40%; margin-right: -40px; z-index: 2;">
        <img src="/static/images/AW_2.jpg"/>
      </div>
      <div class="col" style="width: 60%">
        <img src="/static/images/AW_3.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div class="row" style="z-index: 2; position: relative;">
      <img src="/static/images/AW_4.jpg" style="max-width: 25%; float: right; margin-right: 10%; margin-bottom: -17%;"/>
    </div>

    <div class="spacer-lg"></div>
    <div class="spacer-lg"></div>

    <img src="/static/images/AW_5.jpg"/>

    <div class="spacer-lg"></div>

    <div class="standout-copy" style="width: 87%; text-align: left;">
      Street style meets international football.
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/AW_6.jpg" style="width: 80%"/>

    <img src="/static/images/AW_7.png" style="width: 55%; margin-top: -10%; float: right; margin-bottom: 4%; z-index: 3; position: relative; margin-right: -5%;"/>

    <div class="spacer-lg"></div>

    <img src="/static/images/AW_8.jpg"/>

    <div class="footer">
      <div class="prev">
        <router-link to="/nike-play-gold">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/nike-city-fc">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>
    
  </div>
</template>


<script>
export default {
  name: 'nike-all-whites',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>

.nike-all-whites {

/*  .copy container {
    .col-1 {
      width: 55%;
    }
  }
*/
  .no-negative-top-mobile {
    @media(max-width: 680px) {
      
      .col:first-child {
         width: 39% !important;
         z-index: 2 !important;
        img {
          width: 60% !important;
          margin-right: -10% !important;
          margin-top: 10% !important;
        }
      }

      .col:last-child {
        width: 59% !important;
      } 
    }
  }

}

</style>
